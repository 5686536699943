import * as React from 'react'
import { graphql } from 'gatsby'
import { useRecoilValue } from 'recoil'
import { isMobileState } from '@/recoil/layout'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import Layout from '@/layouts/Default'
import ArticleBlockImage from '@/components/ArticleBlockImage'
import ArticleBlockText from '@/components/ArticleBlockText'
import ArticleBlockSimpleCaps from '@/components/ArticleBlockSimpleCaps'
import ArticleBlockHeadline from '@/components/ArticleBlockHeadline'
import ArticleSummary from '@/components/ArticleSummary'
import ArticleBlockQuote from '@/components/ArticleBlockQuote'
import ArticleBlockCTA from '@/components/ArticleBlockCTA'
import ArticleBlockVideo from '@/components/ArticleBlockVideo'
import { marketCodeManager } from '@/recoil/marketCode'

import moment from 'moment'

import { ArticleWrapper } from './article.style'
import HeroSingleJournal from '@/components/HeroSingleJournal'
import ArticleTags from '@/components/ArticleTags'
import NumberContent from '@/components/NumberContent'
import EditImages from '@/components/EditImages'
import ImageTextPortrait from '@/components/ImageTextPortrait'
import Button from '@/components/Button'
import ImageLandscape from '@/components/ImageLandscape'
import TextCta from '@/components/TextCta'
import WinesSlider from '@/components/WinesSlider'
import WineSuggestion from '@/components/WineSuggestion'
import TagsBlockSlider from '@/components/TagsBlockSlider'

const MAX_TAGS_ARTICLES = 6
const ArticlePage = ({ data, pageContext: { requireAgeGate, pagePath, marketCode: pageMarketCode } }) => {
  const isMobile = useRecoilValue(isMobileState)
  const content = data.drupal.nodeById.fieldNewsBodyLayer
  const date = moment(data.drupal.nodeById?.fieldNewsDate?.date).format('LL')
  const categories = data.drupal.nodeById?.fieldNewsCategories?.map((cat) => ({
    text: cat.entity?.name,
    id: cat.entity?.entityId
  }))
  const location = useLocation()
  const marketCode = useRecoilValue(marketCodeManager)

  const tagsBlockItems = data.drupal.journalQuery.fieldJournalNewsList.reduce((acc, curr) => {
    const item = curr.entity
    const isActualItem = item.path.alias === '/' + pagePath.split('/').slice(2, 4).join('/')
    const itemData = {
      tag: item.fieldNewsCategories[0]?.entity.name,
      url: item.fieldNewsCover?.url, // image
      title: item.fieldNewsTitle,
      path: item.path.alias
    }
    if (itemData.tag === categories[0]?.text && !isActualItem) {
      acc.push(itemData)
    }
    return acc
  }, [])
    .slice(0, MAX_TAGS_ARTICLES)

  const events = data.drupal.eventsQuery.entities.map((event, index) => {
    const event_date = event?.fieldEventEndDate?.value ? event?.fieldEventEndDate?.value : event?.fieldEventStartDate?.value
    return {
      name: event?.title,
      url: event?.path?.alias,
      startDate: event?.fieldEventStartDate?.value,
      endDate: event?.fieldEventEndDate?.value,
      onGoing: moment.utc(new Date()).isBefore(event_date),
      ref: event?.fieldEventLocation,
      hours: event?.fieldEventHours,
      country: event?.fieldEventCountryLocation,
      city: event?.fieldEventLocation,
      content: event?.fieldEventDescription,
      images: [event?.fieldEventCover?.url],
      timestamp: moment(event?.fieldEventStartDate?.value).unix()
    }
  })
    .filter((event) => event.onGoing)
    .sort((ea, eb) => ea.timestamp - eb.timestamp)

  return (
    <Layout
      entityBundle='news'
      path={ pagePath }
      requireAgeGate={ requireAgeGate }
      pageType='Editorial'
      contentType={ categories }
      pageMarketCode={ pageMarketCode }
    >
      <ArticleWrapper>
        <Helmet>
          <title>{data.drupal.nodeById?.fieldNewsMetaTags.entity.fieldMetaTitle}</title>
          <meta name='description' content={ data.drupal.nodeById?.fieldNewsMetaTags.entity.fieldMetaDescription } />
          <meta property='og:title' content={ data.drupal.nodeById?.fieldNewsMetaTags.entity.fieldMetaTitle } />
          <meta
            property='og:description'
            content={ data.drupal.nodeById?.fieldNewsMetaTags.entity.fieldMetaDescription }
          />
          <meta property='og:url' content={ `${process.env.URL || process.env.GATSBY_DRUPAL_URL}${pagePath}` } />
          <meta property='og:site_name' content='Cloudy Bay' />
          <meta property='og:type' content='article' />
          {data.drupal.nodeById?.fieldNewsMetaTags.entity.fieldMetaOpenGraphImage && <meta
            property='og:image'
            content={ data.drupal.nodeById?.fieldNewsMetaTags.entity.fieldMetaOpenGraphImage?.url }
                                                                                     />}
        </Helmet>

        <HeroSingleJournal
          backLabel={ data.drupal.journalQuery.title }
          title={ data.drupal.nodeById?.fieldNewsTitle }
          date={ data.drupal.nodeById?.fieldNewsDate.date }
          image={ isMobile ? data.drupal.nodeById?.fieldNewsCoverMobile?.url : data.drupal.nodeById?.fieldNewsCover?.url }
          backCta={ `/${marketCode}/journal` }
        />

        {content.map((row) => {
          switch (row.entity.type.targetId) {
            case 'wine_suggestion':
              return (
                <WineSuggestion
                  subtitle={ row.entity.fieldTitle }
                  title={ row.entity.fieldWineSuggestionProduct.entity.fieldWineProductName }
                  label={ `from <span>$${row.entity.fieldWineSuggestionProduct.entity.fieldWineProductPrice}</span>` }
                  text={ row.entity.fieldWineSuggestionProduct.entity.fieldWineProductShortDesc }
                  image={ row.entity.fieldSuggestionWineVisual?.url }
                  alt={ row.entity.fieldSuggestionWineVisual?.alt }
                  actions={ [
                    <Button
                      key={ 1 }
                      to={ row.entity.fieldWineSuggestionProduct.entity.fieldWineProductDiscoverCta?.url.path }
                    >
                      {row.entity.fieldWineSuggestionProduct.entity.fieldWineProductDiscoverCta.title}
                    </Button>
                  ] }
                />
              )

            case 'numbered_list':
              return (
                <NumberContent
                  items={ row.entity.fieldItems.map((item) => ({
                    number: item.entity.fieldNumber,
                    text: item.entity.fieldText
                  })) }
                />
              )

            case 'news_images':
              return (
                <EditImages
                  display={ row.entity.fieldDisplay }
                  image1={ row.entity.fieldImageLeft?.url }
                  alt1={ row.entity.fieldImageLeft?.alt }
                  caption1={ row.entity.fieldImageLeft?.title }
                  image2={ row.entity.fieldImageRight?.url }
                  alt2={ row.entity.fieldImageRight?.alt }
                  caption2={ row.entity.fieldImageRight?.title }
                />
              )

            case 'news_text_image_portrait':
              const displayButton = row.entity.fieldNewsPortraitLink?.url.path && row.entity.fieldNewsPortraitLink?.title
              return (
                <ImageTextPortrait
                  subtitle={ row.entity.fieldNewsPortraitSubtitle }
                  title={ row.entity.fieldNewsPortraitTitle }
                  display={ row.entity.fieldNewsPortraitDisplay }
                  text={ row.entity.fieldNewsPortraitText }
                  image={ row.entity.fieldNewsPortraitImage?.url }
                  video={ row.entity.fieldNewsPortraitVideo?.uri }
                  alt={ row.entity.fieldNewsPortraitImage?.alt }
                  caption={ row.entity.fieldNewsPortraitImage?.title }
                  actions={ displayButton && [
                    <Button
                      key={ 1 }
                      to={ row.entity.fieldNewsPortraitLink?.url.path }
                    >{row.entity.fieldNewsPortraitLink?.title}
                    </Button>
                  ] }
                />
              )

            case 'news_text_cta':
              return (
                <TextCta
                  title={ row.entity.fieldNewsTextCtaTitle }
                  subtitle={ row.entity.fieldNewsTextCtaSubtitle }
                  text={ row.entity.fieldNewsTextCtaContent }
                  actions={ row.entity.fieldNewsTextCtaLink.map((link, i) =>
                    <Button key={ i } target='_blank' to={ link?.url.path }>{link?.title}</Button>) }
                />
              )
            case 'news_image_landscape':
              return (
                <ImageLandscape
                  image={ row.entity.fieldNewsLandscapeImage?.url }
                  alt={ row.entity.fieldNewsLandscapeImage?.alt }
                  caption={ row.entity.fieldNewsLandscapeImage?.title }
                  isLarge={ row.entity.fieldNewsLandscapeDisplay }
                />
              )

            case 'simple_caps_section':
              return (
                <ArticleBlockSimpleCaps
                  text={ row.entity.fieldSimpleCapsSectContent }
                />
              )

            case 'headline':
              return (
                <ArticleBlockHeadline
                  text={ row.entity.fieldHeadlineContent }
                  alignment={ row.entity.fieldTextAlignment }
                />
              )

            case 'simple_text_1_column':

              return (
                <ArticleBlockText
                  text1={ row.entity.fieldSimpTxt1colSectContent }
                />
              )

            case 'simple_text_1_column_wysiwyg':
              return (
                <ArticleBlockText
                  text1={ row.entity.fieldSimpTxt1colWysiwyg.value?.replace(/\$market/g, marketCode) }
                  action={ (row.entity.fieldSimpTxt1colButton?.url?.path && row.entity.fieldSimpTxt1colButton?.title) &&
                    <Button
                      target='_blank'
                      to={ row.entity.fieldSimpTxt1colButton?.url?.path }
                    >{row.entity.fieldSimpTxt1colButton?.title}
                    </Button> }
                />
              )
            case 'simple_text_2_columns':
              return (
                <ArticleBlockText
                  text1={ row.entity.fieldSimpTxt2colTxtColumn1 }
                  text2={ row.entity.fieldSimpTxt2colTxtColumn2 }
                />
              )

            case 'simple_image':
              return (
                <ArticleBlockImage
                  src={ isMobile ? row.entity.fieldSimpleImageMobile?.url : row.entity.fieldSimpleImageDesktop?.url }
                />
              )

            case 'standard_quote_with_author':
              return (
                <ArticleBlockQuote
                  author={ row.entity.fieldStandardQuoteAuthorName }
                  quote={ row.entity.fieldStandardQuoteAuthorText }
                />
              )

            case 'cta_block':
              return (
                <ArticleBlockCTA
                  to={ row.entity.fieldCtaBlockCta?.url?.path }
                  label={ row.entity.fieldCtaBlockCta?.title }
                />
              )

            case 'file_linik':
              return (
                <ArticleBlockCTA
                  to={ row.entity.fieldStandardFile?.entity?.url }
                  label={ row.entity.fieldStandardFile?.description }
                  target='_blank'
                />
              )

            case 'product_widget' :

              const widgetCategory = row.entity.fieldWineList?.map((category) => ({
                cat: category.entity.fieldWineProductCategoryName
              }))

              const products = data.drupal.productsQuery.entities

              const result = products.filter(element => {
                return widgetCategory.find(category => category.cat === element.name)
              })

              const productsSliderItems = result.map((category) => ({
                img: category.fieldWineCatPrimaryVintage.entity.fieldWineProdPackshotShadow?.url,
                art: category.fieldWineCatPrimaryVintage.entity.fieldWineProductBackillustrat?.url,
                name: category.name,
                price: category.fieldWineCategoryFromPrice,
                colorBg: category.fieldWineCatPrimaryVintage.entity.fieldWineProductBackcolor,
                colorCta: category.fieldWineCatPrimaryVintage.entity.fieldProductDiscoverColorCta,
                title: category.fieldWineCatPrimaryVintage.entity.fieldCategoryProductName,
                text: category.fieldWineCatPrimaryVintage.entity.fieldWineProductShortDesc,
                path: category.fieldWineCatPrimaryVintage.entity.path.alias
              }
              ))

              return (
                <WinesSlider
                  id='textcercle1'
                  title={ row.entity.fieldTitle }
                  items={ productsSliderItems }
                  label={ row.entity.fieldStandardCta?.title }
                  to={ row.entity.fieldStandardCta?.url.path }
                />
              )

            case 'standard_single_video':
              return (
                <ArticleBlockVideo
                  url={ row.entity.fieldStandardSingleVimeoLink?.uri }
                  isLarge={ row.entity.fieldNewsLandscapeDisplay }
                  title={ data.drupal.nodeById?.fieldNewsTitle }
                />
              )

            case 'summary':
              return (
                <ArticleSummary
                  text={ row.entity.fieldSummaryText }
                />
              )
          }
        })}

        <ArticleTags
          tags={ categories }
        />

        <TagsBlockSlider
          title={ `More from ${categories[0]?.text}` }
          tags={ categories }
          items={ tagsBlockItems }
          label={ `Views all ${categories[0]?.text.toLowerCase()} articles` }
          to={ `../?filters=${categories[0]?.id}&sort=latest` }
        />
      </ArticleWrapper>
    </Layout>
  )
}

export default ArticlePage

export const pageQuery = graphql`
query ArticleQuery($nid: String!){
  drupal {
    journalQuery: nodeById(id: "272") {
      title
      ... on Drupal_NodeJournal {
        fieldJournalNewsList {
          entity {
            ... on Drupal_NodeNews {
              path {
                alias
              }
              fieldNewsTitle
              fieldNewsVideo {
                url {
                  path
                }
              }
              fieldNewsDate {
                date
              }
              fieldNewsCategories {
                entity {
                  ... on Drupal_TaxonomyTermNewsCategories {
                    name
                    entityId
                  }
                }
              }
              fieldNewsCover {
                alt
                url
              }
              fieldNewsCoverMobile {
                alt
                url
              }
              fieldNewsIntroduction
            }
          }
        }
      }
    }
    nodeById(id: $nid) {
      title
      ... on Drupal_NodeNews {
        nid
        fieldNewsTitle
        fieldNewsShortDescription
        fieldNewsIntroduction
        fieldNewsMetaTags {
          entity {
            ... on Drupal_ParagraphMetaTags {
              fieldMetaTitle
              fieldMetaDescription
              fieldMetaOpenGraphImage {
                url
              }
            }
          }
        }
        fieldNewsDate {
          date
        }
        fieldNewsCoverMobile {
          alt
          url
          title
        }
        fieldNewsCover {
          alt
          title
          url
        }
        fieldNewsCategories {
          entity {
            entityId
            ... on Drupal_TaxonomyTermNewsCategories {
              entityRendered
              tid
              name
            }
          }
        }
        fieldNewsBodyLayer {
          entity {
            id
            parentFieldName
            type {
              targetId
            }
            ... on Drupal_ParagraphWineSuggestion {
              id
              fieldTitle
              fieldSuggestionWineVisual {
                url
                alt
              }
              fieldWineSuggestionProduct {
                entity {
                  ... on Drupal_NodeWineProduct {
                    fieldWineProductTastesubtitle
                    fieldWineProductName
                    fieldWineProductShortDesc
                    fieldWineProductPrice
                    fieldWineProductDiscoverCta {
                      url {
                        path
                      }
                      title
                    }
                    fieldWineProductImageGallery {
                      entity {
                        ... on Drupal_ParagraphWineProductImageGallery {
                          fieldWineProductImageItem {
                            entity {
                              ... on Drupal_ParagraphWineProductImageGalleryItem {
                                fieldWineProductImage {
                                  url
                                  alt
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on Drupal_ParagraphSimpleText1Column {
              id
              fieldSimpTxt1colSectContent
            }
            ... on Drupal_ParagraphSimpleText1ColumnWysiwyg {
              id
              fieldSimpTxt1colWysiwyg {
                value
              }
              fieldSimpTxt1colButton {
                url {
                  path
                }
                title
              }
            }
            ... on Drupal_ParagraphNumberedList {
              fieldItems {
                entity {
                  ... on Drupal_ParagraphNumberedItems {
                    fieldNumber
                    fieldText
                  }
                }
              }
            }
            ... on Drupal_ParagraphNewsImages {
              fieldDisplay
              fieldImageRight {
                alt
                url
                title
              }
              fieldImageLeft {
                alt
                url
                title
              }
            }
            ... on Drupal_ParagraphNewsTextImagePortrait {
              fieldNewsPortraitImage {
                alt
                url
                title
              }
              fieldNewsPortraitText
              fieldNewsPortraitSubtitle
              fieldNewsPortraitTitle
              fieldNewsPortraitDisplay
              fieldNewsPortraitVideo {
                uri
              }
              fieldNewsPortraitLink {
                url {
                  path
                }
                title
              }
            }
            ... on Drupal_ParagraphSummary {
              fieldSummaryText
            }
            ... on Drupal_ParagraphNewsTextCta {
              id
              fieldNewsTextCtaTitle
              fieldNewsTextCtaSubtitle
              fieldNewsTextCtaContent
              fieldNewsTextCtaLink {
                url {
                  path
                }
                title
              }
            }
            ... on Drupal_ParagraphNewsImageLandscape {
              fieldNewsLandscapeDisplay
              fieldNewsLandscapeImage {
                alt
                url
                title
              }
            }
            ... on Drupal_ParagraphProductWidget {
              id
              fieldTitle
              fieldStandardCta {
                url {
                  path
                }
                title
              }
              fieldWineList {
                entity {
                  ... on Drupal_TaxonomyTermWineCategory {
                    tid
                    fieldWproductCatDescription
                    fieldWineProductCategoryName
                  }
                }
              }
            }
            ... on Drupal_ParagraphHeadline {
              id
              fieldHeadlineContent
              fieldTextAlignment
            }
            ... on Drupal_ParagraphSimpleCapsSection {
              id
              fieldSimpleCapsSectContent
            }
            ... on Drupal_ParagraphSimpleText2Columns {
              id
              fieldSimpTxt2colTxtColumn1
              fieldSimpTxt2colTxtColumn2
            }
            ... on Drupal_ParagraphSimpleImage {
              id
              fieldSimpleImageDesktop {
                alt
                url
              }
              fieldSimpleImageMobile {
                alt
                url
              }
            }
            ... on Drupal_ParagraphStandardQuoteWithAuthor {
              id
              fieldStandardQuoteAuthorName
              fieldStandardQuoteAuthorText
            }
            ... on Drupal_ParagraphCtaBlock {
              id
              fieldCtaBlockCta {
                url {
                  path
                }
                title
              }
            }
            ...on Drupal_ParagraphStandardSingleVideo {
              fieldNewsLandscapeDisplay
              fieldStandardSingleVimeoLink {
                uri
              }
            }
          }
        }
      }
    }
    productsQuery: taxonomyTermQuery(
      limit: 1000
      offset: 0
      filter: {conditions: [{operator: EQUAL, field: "vid", value: ["wine_category"]}]}
    ) {
      entities {
        ... on Drupal_TaxonomyTermWineCategory {
          name
          fieldWineCategorySlugUrl
          fieldWineCatPrimaryVintage {
            entity {
              ...on Drupal_NodeWineProduct {
                nid
                path {
                  alias
                }
                title
                fieldProductDiscoverColorCta
                fieldWineProductBackcolor
                fieldWineProductBackillustrat {
                  url
                }
                fieldWineProductShortDesc
                fieldWineProductName
                fieldCategoryProductName
                fieldWineProductDiscoverCta {
                  title
                }
                fieldWineProdPackshotShadow {
                  url
                }
              }
            }
          }
          fieldWineCategoryFromPrice
        }
      }
    }
    eventsQuery: nodeQuery(
      filter: {conditions: [
          {field: "type", value: "events", operator: EQUAL},
          # {field: "field_event_country", value: [$marketId], operator: EQUAL},
          {field: "field_event_types", value: "journal", operator: EQUAL}
          {operator: EQUAL, field: "status", value: "1"}
        ]}
        limit: 1000
      ) {
      entities {
        ... on Drupal_NodeEvents {
          title
          path{
            alias
          }
          fieldEventStartDate {
            value
            date
          }
          fieldEventEndDate {
            value
            date
          }
          fieldEventCountry {
            targetId
          }
          fieldEventCountryLocation
          fieldEventLocation
          fieldEventCover {
            height
            url
            width
            targetId
            alt
            title
          }
          fieldEventCoverMobile {
            height
            url
            width
            targetId
            alt
            title
          }
          fieldEventDescription
          fieldEventHours
        }
      }
    }
  }
}
`
