import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import Player from '@vimeo/player'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade, Pagination } from 'swiper'

import {
  Content,
  ContentButton,
  ContentCategories,
  ContentCategory,
  ContentDate,
  ContentLink,
  ContentTitle,
  Wrapper
} from './style'
import { Container, Overlay } from '@/components/HomeHero/Item/style'
import resolveAssetURL from '@/utils/resolveAssetURL'
import { CloseButton, Viewer } from '@/components/ImageTextPortrait/style'
import { ViewerWrapper, ViewVideo } from '@/components/CardGallery/style'
import { Image } from '@/components/Block/Sliders/MediaSlider/MediaCard/style'
import closeButton from '@/images/close-button.svg'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { journalVideoOpen, journalVideoTitle, journalVideoUrl } from '@/recoil/journal'
import { BANNER_INTERACTION, VIDEO_COMPLETE, VIDEO_PROGRESS, VIDEO_START } from '@/utils/gtmEvents'
const DEFAULT_VIDEO_THRESHOLDS = [
  {
    time: 25,
    reached: false
  },
  {
    time: 50,
    reached: false
  },
  {
    time: 75,
    reached: false
  }
]

const HeroJournal = ({ slides }) => {
  const videoOpen = useRecoilValue(journalVideoOpen)
  const videoUrl = useRecoilValue(journalVideoUrl)
  const videoTitle = useRecoilValue(journalVideoTitle)
  const setVideoOpen = useSetRecoilState(journalVideoOpen)
  const setVideoUrl = useSetRecoilState(journalVideoUrl)
  const setVideoTitle = useSetRecoilState(journalVideoTitle)
  const [isOpen, setIsOpen] = useState(false)
  const iframeRef = useRef(null)
  const [videoThresholds, setVideoThresholds] = useState(DEFAULT_VIDEO_THRESHOLDS)

  useEffect(() => {
    if (videoUrl && videoOpen && iframeRef.current && isOpen) {
      const player = new Player(iframeRef.current)
      const flatThresholds = videoThresholds.reduce((acc, curr) => {
        acc.push(curr.time)
        return acc
      }, [])
      player.on('timeupdate', (e) => {
        const percent = (e.percent * 100).toFixed(0)
        flatThresholds.forEach((val, i) => {
          const valReached = percent >= val - 2 && percent < val + 2
          const isAlreadyReached = videoThresholds[i].reached
          if (valReached && !isAlreadyReached) {
            videoThresholds[i].reached = true
            typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push({
              event: VIDEO_PROGRESS,
              video_title: videoTitle,
              video_percent: videoThresholds[i].time
            })
          }
        })
      })
      player.on('ended', (e) => {
        typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push({
          event: VIDEO_COMPLETE,
          video_title: videoTitle
        })
        typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push({
          event: VIDEO_PROGRESS,
          video_title: videoTitle,
          video_percent: 100
        })
      })
    }
  }, [isOpen, videoUrl, videoOpen])

  const triggerGtmEventBanner = (ctaName, bannerName) => {
    typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push({
      event: BANNER_INTERACTION,
      cta_name: ctaName,
      banner_name: bannerName
    })
  }
  const triggerGtmEventVideo = (videoTitle) => {
    setVideoThresholds(DEFAULT_VIDEO_THRESHOLDS)
    typeof window !== 'undefined' && window.dataLayer && window.dataLayer.push({
      event: VIDEO_START,
      video_title: videoTitle
    })
  }

  return (
    <Wrapper>
      <Swiper
        modules={ [Pagination, Autoplay, EffectFade] }
        pagination={ { clickable: true } }
        loop
        shortSwipes={ false }
        longSwipesRatio={ 0.2 }
        autoplay={ {
          disableOnInteraction: false,
          delay: 7000
        } }
        effect='fade'
        watchSlidesProgress
      >
        {
          slides.slice(0, 3).map((slide, i) => {
            const {
              video,
              image,
              category,
              title,
              ctaTo
            } = slide
            const date = moment(slide.date).format('LL')
            const datetxt = date.toString()
            const code = video?.split('/')[3]
            return (
              <SwiperSlide key={ `slide-${i}` }>
                <Container background={ resolveAssetURL(image) }>
                  <Overlay>
                    <Content>
                      <ContentCategories>
                        <ContentCategory>{category}</ContentCategory>
                      </ContentCategories>
                      <ContentTitle>{title}</ContentTitle>
                      <ContentDate>{datetxt}</ContentDate>
                      {!video
                        ? (
                          <ContentLink
                            onClick={ () => {
                              triggerGtmEventBanner('Learn More', title)
                            } }
                            to={ ctaTo }
                          >Learn More
                          </ContentLink>
                          )
                        : (
                          <ContentButton
                            onClick={ (e) => {
                              e.preventDefault()
                              triggerGtmEventBanner('Watch Video', title)
                              triggerGtmEventVideo(title)
                              setVideoTitle(title)
                              setVideoUrl(`https://player.vimeo.com/video/${code}`)
                              setVideoOpen(true)
                            } }
                          >
                            Watch Video
                          </ContentButton>
                          )}
                    </Content>
                  </Overlay>
                </Container>
              </SwiperSlide>
            )
          })
        }
      </Swiper>
      <Viewer isOpen={ isOpen }>
        <ViewerWrapper>
          <ViewVideo
            ref={ iframeRef }
            src={ `${videoUrl}` }
            allow='encrypted-media'
            frameborder='0'
            onLoad={ (e) => {
              setIsOpen(videoOpen)
            } }
          />
        </ViewerWrapper>
        <CloseButton
          onClick={
            () => {
              setVideoTitle('')
              setVideoUrl(null)
              setVideoOpen(false)
              setIsOpen(false)
            }
          }
        >
          <Image src={ closeButton } alt='close icon' />
        </CloseButton>
      </Viewer>
    </Wrapper>
  )
}

HeroJournal.propTypes = {
  slides: PropTypes.array.isRequired
}

export default HeroJournal
